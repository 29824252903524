<template>
  <b-modal
    id="modal-expense"
    :title="isEdit? 'Edit Expense' : 'New Expense'"
    hide-footer
    @hidden="cancel"
    no-close-on-esc
    no-close-on-backdrop
  >
    <validation-observer ref="form" slim>
      <b-row>
        <b-col>
          <validation-provider name="Type" rules="required" v-slot="{ errors }">
            <b-form-group label="Type *" :invalid-feedback="errors[0]">
              <custom-select
                v-model="addExpenseForm.budgetTypeId"
                label="typeName"
                placeholder="Select type"
                :options="typeList"
                @input="fetchActivityList(addExpenseForm.budgetTypeId)"
                :class="{ 'is-invalid': errors.length }"
                :reduce="({ id }) => id"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Activity"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Activity *" :invalid-feedback="errors[0]">
              <custom-select
                v-model="addExpenseForm.activityId"
                label="name"
                placeholder="Select activity"
                :options="activityList"
                :class="{ 'is-invalid': errors.length }"
                :reduce="({ id }) => id"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider name="Transaction Date" v-slot="{ errors }">
            <b-form-group label="Date *" :invalid-feedback="errors[0]">
              <b-form-datepicker
                v-model="addExpenseForm.transactionDate"
                placeholder="DD/MMM/YYYY"
                @change="changeFormatDate()"
                :class="{ 'is-invalid': errors.length }"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Descriptions"
            rules="required|max:255"
            v-slot="{ errors }"
          >
            <b-form-group label="Descriptions *" :invalid-feedback="errors[0]">
              <b-form-textarea
                v-model="addExpenseForm.description"
                placeholder="Enter your descriptions"
                rows="4"
                :class="{ 'is-invalid': errors.length }"
                max-rows="6"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <validation-provider
            name="Amount"
            rules="required|min_value:0"
            v-slot="{ errors }"
          >
            <b-form-group label="Amount *" :invalid-feedback="errors[0]">
              <b-input-group
                prepend="Rp"
                :class="{ 'is-invalid': errors.length }"
              >
                <money
                  class="form-control"
                  :class="{ 'is-invalid': errors.length }"
                  v-model="addExpenseForm.billAmount"
                  placeholder="0"
                />
              </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Upload Bills">
            <div class="dropbox_e">
              <input
                type="file"
                accept="application/pdf, image/jpeg, image/png, image/jpg, image/bmp"
                model="file"
                placeholder="select file"
                @change="onFileChange"
                class="input-file_e"
              />
              <div id="preview" class="mt-1">
                <img
                  src="@/assets/fi_upload.png"
                  class="icon-style"
                />
              </div>
            </div>
            <br />
            <label class="text-upload">*Maximum file upload 10MB</label>
            <br />
            <label class="text-upload"
              >**Format file can be .pdf or image</label
            >
            <br />
            <div class="mt-2 text-upload">
              <b-row
                v-if="file || (addExpenseForm.expenseBills && addExpenseForm.expenseBills.length)"
                class="d-flex ml-1"
              >
                <div
                  v-if="file"
                  class="name-file"
                >
                  {{ file ? file.name : null }}
                </div>
                <div
                  v-else-if="addExpenseForm.expenseBills.length"
                  class="name-file"
                >
                  {{ addExpenseForm.expenseBills ? addExpenseForm.expenseBills[0].originalFilename : null }}
                </div>
                <div
                  v-if="(file && file.name) || (addExpenseForm.expenseBills.length)"
                  align="right"
                >
                  <img
                    src="@/assets/Light.png"
                    v-on:click="cancelUpload()"
                    class="icon"
                    style="cursor: pointer;"
                  />
                </div>
              </b-row>
            </div>
            <br />
          </b-form-group>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row class="mb-3">
      <b-col>
        <b-button
          class="merge reset"
          variant="outline-warning"
          block
          @click="cancel"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col>
        <b-button
          class="primary shadow"
          variant="warning"
          block
          @click="submit"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import api from '@/api'

export default {
  components: {},
  props: {
    addExpenseForm: {
      type: Object,
      default: () => ({})
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    file: null,
    activityList: []
  }),
  created() {
    this.fetchActivity()
  },
  methods: {
    async fetchActivityList(type) {
      this.addExpenseForm.activityId = null
      this.activityList =
        this.typeList.find((element) => element.id === type).budgetActivities ||
        []
    },
    async fetchActivity() {
      const { data } = await api.budget.list({ pageSize: 100 })
      this.activityList = data
    },
    onFileChange(e) {
      this.file = e.target.files[0]
      var size = parseFloat(
        this.file.size / (1024 * 1024)
      ).toFixed(2)
      if (size > 10) {
        this.$bvToast.toast('Please select file size less than 10 MB', {
          headerClass: 'd-none',
          solid: true,
          variant: 'danger'
        })
        this.file = null
      } else {
        var check = this.file.name.split('.').pop()
        const arrDocType = ['pdf', 'jpeg', 'png', 'jpg', 'bmp']
        if (arrDocType.includes(check.toLowerCase())) {
          this.$bvToast.toast('Success browse file', {
            headerClass: 'd-none',
            solid: true,
            variant: 'success'
          })
        } else {
          this.file = null
          this.$bvToast.toast('Only pdf, image files are allowed!', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        }
      }
    },
    cancelUpload() {
      this.addExpenseForm.expenseBills = []
      this.file = null
    },
    async submit() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        this.isBusy = false
        return false
      }

      if (this.file) {
        this.addExpenseForm.file = this.file
        this.addExpenseForm.billId = null
        this.addExpenseForm.expenseBills = []
      } else {
        this.file = null
        this.addExpenseForm.file = null
        if (this.addExpenseForm.expenseBills && this.addExpenseForm.expenseBills.length) this.addExpenseForm.billId = this.addExpenseForm.expenseBills[0].id
        else this.addExpenseForm.billId = null
      }

      this.$emit('set', this.addExpenseForm)
      this.$bvModal.hide('modal-expense')
      this.file = null
    },
    cancel() {
      this.$emit('resetForm')
      this.$bvModal.hide('modal-expense')
    }
  }
}
</script>

<style lang="scss" scoped>
.col,
.col-2,
.col-3,
.col-6 {
  padding: 0 16px;
}
.dropbox_e {
  border: 2px solid #d3d3d3;
  text-align: right;
}
.input-file_e {
  margin-left: -455px;
  opacity: 0;
  width: 455px;
  height: 30px;
  position: absolute;
  cursor: pointer;
}
.icon-style {
  width: 15px;
  margin-top: -9px;
  margin-right: 5px;
}
.name-file {
  width: 430px;
}
</style>
