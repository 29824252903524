var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-view-expense",attrs:{"id":"modal-view-expense","title":"View Expense","centered":"","hide-footer":"","hide-header":""}},[_c('div',{staticClass:"p-3"},[_c('div',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Type"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.viewExpense.budgetActivity ? _vm.viewExpense.budgetActivity.budgetType.typeName : "no data")+" ")])])],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Activity"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.viewExpense.budgetActivity ? _vm.viewExpense.budgetActivity.name : "no data"))])])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Date"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.moment(_vm.viewExpense.transactionDate).format("DD/MM/YYYY"))+" ")])])],1),_c('b-col',[_c('div',{staticClass:"text-muted"},[_vm._v("Amount")]),_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.formatMoney(_vm.viewExpense.billAmount, { symbol: "Rp.", precision: 0, thousand: ".", }))+" ")])])],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Description"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.viewExpense.description)+" ")])])],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Uploaded File"}},[(
                _vm.viewExpense.expenseBills && _vm.viewExpense.expenseBills.length
              )?_c('a',{attrs:{"href":""},domProps:{"textContent":_vm._s(
                _vm.viewExpense.expenseBills[0].originalFilename.length > 60
                  ? _vm.viewExpense.expenseBills[0].originalFilename.slice(
                      0,
                      60
                    ) + '...'
                  : _vm.viewExpense.expenseBills[0].originalFilename
              )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.download(_vm.viewExpense.expenseBills[0])}}}):_c('p',[_vm._v("no attachment")])])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }