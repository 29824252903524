<template>
  <div>
    <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
      <b-row>
        <b-col cols="2">
          <b-form-group label="Type">
            <custom-select
              v-model="filters.budgetType"
              label="typeName"
              type="text"
              multiple
              size="md"
              @input="fetchActivityList(filters.budgetType)"
              placeholder="Select type"
              :options="typeList"
              :reduce="({ typeName }) => typeName"
            />
          </b-form-group>
        </b-col>
        <b-col cols="2">
          <b-form-group label="Activity">
            <custom-select
              v-model="filters.budgetActivity"
              label="name"
              type="text"
              multiple
              placeholder="Select Activity"
              :options="activityList"
              @input="createFilters()"
              :reduce="({ name }) => name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Periode">
            <date-picker
              placeholder="Select Periode"
              v-model="period"
              range
              @input="createFilters()"
            ></date-picker>
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-button
            class="merge reset mr-3 mt-2"
            variant="outline-warning"
            @click="resetFilter"
          >
            Reset Filter
          </b-button>
          <b-button
            class="next merge mr-3 mt-2"
            variant="warning"
            @click="changeFilter"
          >
            Apply Filter
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="mt-3" body-class="py-4 px-3">
      <div class="mt-3 d-flex justify-content-between">
        <div>
          <b-button
            class="next mr-3"
            variant="warning"
            v-b-modal.modal-expense
          >
            Add Expense
          </b-button>
          <b-button
            class="reset"
            variant="outline-warning"
            @click="
              () => {
                createFilters();
                exportExpense();
              }
            "
          >
            Export
          </b-button>
        </div>
        <b-form-group class="m-0">
          <b-input-group>
            <b-form-input
              v-model="search"
              placeholder="Search..."
              @change="
                () => {
                  createFilters();
                  $refs.table.refresh();
                }
              "
            />
          </b-input-group>
        </b-form-group>
      </div>
      <br />
      <b-row v-show="!items.length">
        <b-col>
          <div align="center">
            <img :src="require('@/assets/icon-no-invoice.png')" />
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        v-show="items.length"
        :items="fetchExpenses"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        striped
        responsive
      >
        <template #cell(no)="{ index }">
          {{ index + 1 }}
        </template>

        <template #cell(type)="{ item }">
          {{
            item.budgetActivity
              ? item.budgetActivity.budgetType.typeName
              : "no data"
          }}
        </template>

        <template #cell(activity)="{ item }">
          {{ item.budgetActivity ? item.budgetActivity.name : "no data" }}
        </template>

        <template #cell(transactionDate)="{ item }">
          {{ moment(item.transactionDate).format("DD MMM YYYY") || "no data" }}
        </template>

        <template #cell(description)="{ item }">
          <div class="description">
            {{ item.description || "no data" }}
          </div>
        </template>

        <template #cell(amount)="{ item }">
          {{
            formatMoney(item.billAmount, {
              symbol: "Rp.",
              precision: 0,
              thousand: ".",
            })
          }}
        </template>

        <template #cell(action)="{ item }">
          <b-link class="mr-2" @click="openModal(item, true)">
            <fa-icon class="text-muted" icon="edit" />
          </b-link>
          <b-link @click="goToView(item.id)">
            <fa-icon class="text-muted" icon="eye" />
          </b-link>
          <b-link @click="goToDelete(item.id)">
            <fa-icon class="text-muted ml-2" icon="trash" />
          </b-link>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
        <div>
          <span>Show</span>
          <b-form-select
            v-model="perPage"
            :options="pageOptions"
            @change="fetchExpenses"
            class="page-option ml-2 mr-2 w-auto"
            size="sm"
          />
          <span>Entries</span>
        </div>
        <div>
          <b-pagination
            class="mb-0"
            v-model="currentPage"
            :per-page="perPage"
            :total-rows="totalRows"
            pills
            hide-goto-end-buttons
            @input="fetchExpenses"
          />
          <span class="show-entries mr-auto">
            {{
              `Show ${
                totalRows === 0 ? 0 : (currentPage - 1) * perPage + 1
              } to ${
                totalRows > currentPage * perPage
                  ? currentPage * perPage
                  : totalRows
              } of ${totalRows} entries`
            }}
          </span>
        </div>
      </div>
    </b-card>
    <modal-expense
      :typeList="typeList"
      :addExpenseForm="addExpenseForm"
      :isEdit="isEdit"
      @set="saveExpenses"
      @resetForm="resetForm"
    />
    <modal-confirmation-delete @click="deleteExpense" :id="id" />
    <modal-view-expense :viewExpense="viewExpense" />
  </div>
</template>

<script>
import api from '@/api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import modalExpense from './ModalExpense.vue'
import modalViewExpense from './ModalViewExpense.vue'
import modalConfirmationDelete from './ModalConfirmationDelete.vue'
import { formatMoney } from 'accounting-js'
import moment from 'moment'

export default {
  components: {
    DatePicker,
    modalExpense,
    modalViewExpense,
    modalConfirmationDelete
  },
  data: () => ({
    id: '',
    isEdit: false,
    sortBy: '',
    search: '',
    filters: {},
    busy: false,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      {
        key: 'no',
        label: 'No',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'type',
        label: 'Type',
        tdClass: 'text-center',
        thClass: 'text-center'
      },
      {
        key: 'activity',
        label: 'Activity',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'transactionDate',
        label: 'Transaction Date',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'description',
        label: 'Description',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true,
        thStyle: { width: '250px' }
      },
      {
        key: 'amount',
        label: 'Amount',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true
      },
      {
        key: 'action',
        label: 'Action',
        tdClass: 'text-center',
        thClass: 'text-center',
        sortable: true
      }
    ],
    items: [],
    pageOptions: [10, 20, 50, 100],
    typeList: [],
    activityList: [],
    period: [],
    addExpenseForm: {},
    viewExpense: {},
    typeId: [],
    filtersSearch: ''
  }),
  created() {
    this.fetchActivity()
    this.fetchType()
  },
  methods: {
    formatMoney,
    createFilters() {
      this.filtersSearch = this.search ? `search==${this.search}` : ''
      if (this.period.length && this.period[0] && this.period[1]) {
        const start = moment(this.period[0]).format('YYYY-MM-DD')
        const end = moment(this.period[1]).format('YYYY-MM-DD')
        this.filtersSearch += `periodeExpense==${start};${end}`
      }
      if (this.filters.budgetType || this.filters.budgetActivity) {
        for (const key in this.filters) {
          if (this.filters[key].length === 1) {
            this.filtersSearch += `,${key}==${this.filters[key]}`
          } else if (this.filters[key].length > 1) {
            this.filtersSearch += `,${key}==${this.filters[key].join('|')}`
          }
        }
      }
      if (!this.filtersSearch) {
        this.$refs.table.refresh()
      }
    },
    changeFilter() {
      this.$refs.table.refresh()
    },
    resetFilter() {
      this.period = []
      this.filters = {}
      this.currentPage = 1
      this.filtersSearch = ''
      this.$refs.table.refresh()
    },
    async fetchExpenses() {
      const { data } = await api.projectCost.listExpense({
        page: this.currentPage,
        pageSize: this.perPage,
        filters: this.filtersSearch,
        Sorts: this.sortBy
      })
      this.items = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      this.items.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      return this.items
    },
    openModal(item, isEdit) {
      this.isEdit = isEdit
      this.addExpenseForm = item !== null ? item : {}
      this.addExpenseForm.projectId = item.projectId || null
      this.addExpenseForm.budgetTypeId =
        item !== null ? item.budgetActivity.budgetType.id : null
      this.addExpenseForm.expenseBills = item ? item.expenseBills : []
      this.addExpenseForm.transactionDate = item
        ? moment(item.transactionDate).format('YYYY-MM-DD')
        : null
      this.isActivity = isEdit
      var file = null
      if (item !== null) {
        file = {
          name:
            item.expenseBills.length > 0
              ? this.addExpenseForm.expenseBills[0].originalFilename
              : null
        }
      }
      this.addExpenseForm.file = file
      this.$bvModal.show('modal-expense')
    },
    async fetchType() {
      const { data } = await api.budget.type({
        pageSize: 100,
        sorts: 'typeName'
      })
      this.typeList = data
    },
    async fetchActivityList(name) {
      this.createFilters()
      if (name.length) {
        this.activityList = []
        this.typeId = []
        for (let j = 0; j < name.length; j++) {
          for (let i = 0; i < this.typeList.length; i++) {
            if (this.typeList[i].typeName === name[j]) {
              this.typeId.push(this.typeList[i].id)
            }
          }
        }
        for (let i = 0; i < this.typeId.length; i++) {
          this.activityList = this.activityList.concat(
            this.typeList.find((element) => element.id === this.typeId[i])
              .budgetActivities || []
          )
        }
      } else {
        this.fetchActivity()
        this.$refs.table.refresh()
      }
    },
    async fetchActivity() {
      this.activityList = []
      const { data } = await api.budget.list({ pageSize: 100 })
      this.activityList = data
    },
    async goToView(id) {
      const { data } = await api.projectCost.viewExpense(id)
      this.viewExpense = data.data
      this.$bvModal.show('modal-view-expense')
    },
    async saveExpenses(addExpenseForm) {
      this.resetFilter()
      try {
        const formData = new FormData()
        formData.append('projectId', addExpenseForm.projectId || null)
        formData.append('activityId', addExpenseForm.activityId)
        formData.append('description', addExpenseForm.description)
        formData.append('billAmount', addExpenseForm.billAmount)
        formData.append('bills', addExpenseForm.file)
        formData.append('billId', addExpenseForm.billId)
        formData.append(
          'transactionDate',
          moment(addExpenseForm.transactionDate).format('YYYY-MM-DD')
        )
        if (!this.isEdit) {
          await api.projectCost.saveExpense(formData)
        } else {
          await api.projectCost.editExpense(this.addExpenseForm.id, formData)
        }
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Success save Expense', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$refs.table.refresh()
        setTimeout(() => {
          this.$bvModal.hide('modal-expense')
        }, 2000)
        this.addExpenseForm = this.$options.data().addExpenseForm
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
        this.$refs.table.refresh()
      }
    },
    async goToDelete(id) {
      this.id = id
      this.$bvModal.show('modal-confirmation-delete')
    },
    async deleteExpense() {
      try {
        this.isBusy = true
        await api.projectCost.deleteExpense(this.id)
        this.$nextTick(() => {
          setTimeout(() => {
            this.$bvToast.toast('Expense succesfully deleted', {
              headerClass: 'd-none',
              solid: true,
              variant: 'success'
            })
          }, 500)
        })
        this.$refs.table.refresh()
        setTimeout(() => {
          this.$bvModal.hide('modal-confirmation-delete')
        }, 2000)
      } catch (error) {
        this.isBusy = false
        this.$nextTick(() => {
          this.$bvToast.toast(
            error.message ? error.message.messageEng : error.data.messageEng,
            {
              headerClass: 'd-none',
              solid: true,
              variant: 'danger'
            }
          )
        })
      }
    },
    async exportExpense() {
      if (this.period.length && this.period[0] && this.period[1]) {
        try {
          await api.projectCost
            .exportExpense({
              filters: this.filtersSearch,
              sorts: this.sortBy,
              startDate: moment(this.period[0]).format('YYYY-MM-DD'),
              endDate: moment(this.period[1]).format('YYYY-MM-DD')
            })
            .then((response) => {
              if (response.data.size > 4980) {
                const url = URL.createObjectURL(
                  new Blob([response.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  })
                )
                const link = document.createElement('a')
                const date = moment(Date.now()).format('YYYY/MM/DD')
                const fileName = `expense-report-${date}`
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvToast.toast('Success export expense data', {
                      headerClass: 'd-none',
                      solid: true,
                      variant: 'success'
                    })
                  }, 500)
                })
              } else {
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.$bvToast.toast("there's no expense data in this period", {
                      headerClass: 'd-none',
                      solid: true,
                      variant: 'danger'
                    })
                  }, 500)
                })
              }
            })
        } catch (error) {
          this.$nextTick(() => {
            this.$bvToast.toast(
              error.message ? error.message.messageEng : error.data.messageEng,
              {
                headerClass: 'd-none',
                solid: true,
                variant: 'danger'
              }
            )
          })
        }
      } else {
        this.$nextTick(() => {
          this.$bvToast.toast('Please, first select the period', {
            headerClass: 'd-none',
            solid: true,
            variant: 'danger'
          })
        })
      }
    },
    resetForm() {
      this.addExpenseForm = this.$options.data().form
    }
  }
}
</script>

<style lang="scss" scoped>
.col,
.col-2,
.col-3,
.col-6 {
  padding: 0 16px;
}

.card-list {
  display: grid;
  grid-gap: 1em;
}

.draft {
  width: 53px;
  height: 22px;

  /* Gray 1 */

  background: #a4a4a4;
  border-radius: 4px;
}
.paid {
  width: 49px;
  height: 22px;

  /* Green 2 */

  background: #4caf50;
  border-radius: 4px;
}
.unpaid {
  width: 64px;
  height: 22px;
  /* Main Color Darker */

  background: #f7ac26;
  border-radius: 4px;
}
.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  gap: 10px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
</style>
